body {
	display: flex;
	margin: 0;
}

.app {
	display: flex;
	width: 100vw;
	height: 100vh;
}

.map {
	flex-grow: 1;	
}